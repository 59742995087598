import { graphql, useStaticQuery } from "gatsby";
import React from "react";

import Contact from "../components/Contact";
import Layout from "../components/Layout";
import SEO from "../components/Seo";

const ContactPage = () => {
  const data = useStaticQuery(graphql`
    query ContactQuery {
      allContentfulContact {
        edges {
          node {
            body {
              childMarkdownRemark {
                html
              }
            }
            image {
              fluid(maxWidth: 2500, quality: 100) {
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
            }
            metaDescription
            metaTitle
          }
        }
      }
    }
  `);
  const dataNode = data.allContentfulContact.edges[0].node;

  return (
    <Layout>
      <SEO metaDescription={dataNode.metaDescription} title={dataNode.metaTitle} />
      <Contact body={dataNode.body.childMarkdownRemark.html} image={dataNode.image} />
    </Layout>
  );
};
export default ContactPage;
