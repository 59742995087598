import Img from "gatsby-image";
import React from "react";

import styles from "./index.module.css";

const Contact = props => (
  <div className={styles.el}>
    <div className={styles.container}>
      <div className={styles.body}>
        <div
          dangerouslySetInnerHTML={{
            __html: props.body
          }}
        />
      </div>
      <div className={styles.background}>
        <div className={styles.image}>
          <Img loading="eager" fluid={props.image.fluid} />
        </div>
      </div>
    </div>
  </div>
);

export default Contact;
